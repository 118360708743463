.contacts {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  padding-bottom: 140px;
}

.contacts__img {
  width: 744px;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
  position: absolute;
  left: 321px;
  top: 0;
  z-index: 1;
}

.contacts__img_type_mobile {
  display: none;
}

.contacts__circle {
  width: 466px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  opacity: 0.6;
  background: var(--conf-heading-circle-color);
  filter: blur(232px);
  position: absolute;
  top: 108px;
  right: 66px;
  z-index: 0;
}

.contacts__content {
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding: 0 32px;
  margin-top: -210px;
  z-index: 1;
}

.contacts__container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  gap: 60px;
}

.contacts__form-container {
  width: 100%;
  max-width: 620px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  border-radius: 12px;
  background: rgba(var(--neutral-bg-with-opacity), 0.1);
  box-sizing: border-box;
  padding: 40px 30px;
}

.contacts__form-title {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.11;
}

.contacts__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.contacts__inputs {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 12px 20px;
}

.contacts__input {
  width: 100%;
}

.contacts__input_type_textarea {
  grid-column: -2 / -1;
  grid-row: 1 / 4;
}

.contacts__buttons {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 20px;
}

.contacts__submit-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 550;
  line-height: 1.85;
  border-radius: 12px;
  background: var(--secondary-active);
  box-sizing: border-box;
  padding: 10px 0px;
  transition: all 0.2s ease-in-out;
}

.contacts__submit-button:hover {
  opacity: 0.8;
}

.contacts__submit-button_disabled {
  background: var(--primary-selection);
  user-select: none;
  pointer-events: none;
}

.contacts__info-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.contacts__logo {
  width: 200px;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
}

.contacts__info-item {
  display: flex;
  gap: 12px;
}

.contacts__info-icon {
  width: 22px;
  height: 22px;
}

.contacts__info-icon-stroke {
  stroke: var(--primary-active);
}

.contacts__info-text-block {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.contacts__info-link {
  transition: opacity 0.2s ease-in-out;
}

.contacts__info-link:hover {
  opacity: 0.8;
}

.contacts__info-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.37;
}

.contacts__info-text_size_small {
  font-size: 14px;
  line-height: 1;
  opacity: 0.6;
  margin-top: -4px;
}

@media screen and (max-width: 1000px) {
  .contacts__img {
    left: 200px;
  }
}

@media screen and (max-width: 900px) {
  .contacts {
    padding-bottom: 112px;
  }

  .contacts__img {
    display: none;
  }

  .contacts__img_type_mobile {
    width: 562px;
    display: block;
    left: unset;
    top: 22px;
    margin-left: -6px;
  }

  .contacts__circle {
    display: none;
  }

  .contacts__content {
    padding: 0 26px;
    margin-top: -50px;
  }

  .contacts__container {
    flex-direction: column;
    gap: 40px;
  }

  .contacts__form-container {
    max-width: 100%;
    gap: 30px;
    padding: 30px 20px;
  }

  .contacts__form {
    gap: 14px;
  }

  .contacts__inputs {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .contacts__input_type_textarea {
    height: 100px;
  }

  .contacts__buttons {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .contacts__submit-button {
    font-size: 16px;
    line-height: 1.25;
    border-radius: 8px;
    padding: 12px 0px;
  }

  .contacts__submit-button:hover {
    opacity: 1;
  }

  .contacts__info-container {
    gap: 24px;
  }

  .contacts__logo {
    width: 190px;
  }
}
