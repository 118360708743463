.partners-card {
    /* display: flex;
    flex-direction: column; */
    /* justify-content: space-between; */
    align-items: center;

    border-radius: 12px;
    background: rgba(var(--neutral-bg-with-opacity), 0.08);

    color: var(--neutral-active);
    padding: 30px 30px 20px 30px;
    min-height: 232px;
    text-align: center;

    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    /* grid-template-rows: 1fr 52px; */
    justify-content: center;
    box-sizing: border-box;
    align-items: end;
}

.partners-card_with-desc {
    display: flex;
    flex-direction: column;
    text-align: left;
    min-height: 382px;
    justify-content: flex-end;

    padding: 20px 30px 20px 30px;
    box-sizing: border-box;
    /* justify-content: space-between; */
    align-items: center;

    border-radius: 12px;
    background: rgba(var(--neutral-bg-with-opacity), 0.08);

    color: var(--neutral-active);
}

.partners-card__img {
    /* height: 100%;
    max-height: 96px;
    width: 100%; */
}

.partners-card__text-box {
    display: flex;
    flex-direction: column;
}

.partners-card__title {
    color: var(--neutral-active);
    
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 120% */
}

.partners-card__desc {
    color: var(--neutral-active);
    
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    /* 128.571% */
    opacity: 0.7;
}

.partners-card__link {
    color: var(--link-color);
    
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    /* 133.333% */

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    width: 100%;
}

.partners-card .partners-card__text-box {
    /* margin-top: 30px; */
}

.partners-card_with-desc .partners-card__text-box {
    margin-top: 80px;
    gap: 12px;
}

.partners-card .partners-card__link {
    margin-top: 12px;
}

.partners-card_with-desc .partners-card__link {
    margin-top: 30px;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
}

@media (max-width:600px) {
    .partners-card_with-desc {
        justify-content: center;
        gap: 27px;
        height: fit-content;
        min-height: 288px;
    }

    .partners-card_with-desc .partners-card__text-box {
        margin-top: 36px;
    }
}

@media (max-width:500px) {
    .partners-card {
        padding: 15px;
        /* overflow: hidden; */
    }

    .partners-card .partners-card__title {
        font-size: 15px;

        text-overflow: ellipsis;
        max-width: 100%;
        width: 120px;
        overflow: hidden;
        white-space: nowrap;
    }

}

@media (max-width:470px) {
    .partners-card {
        grid-template-rows: auto;
    }

    .partners-card .partners-card__img {
        width: 100%;
        max-width: 120px;
        pointer-events: none;
        user-select: none;

    }

    .partners-card .partners-card__link {
        font-size: 10px;
    }
}