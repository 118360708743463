.conf-heading {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 32px;
}

.conf-heading__content {
  width: 100%;
  max-width: 1200px;
  position: relative;
  box-sizing: border-box;
  padding: 226px 0 262px;
}

.conf-heading__title {
  max-width: 600px;
  font-size: 60px;
  font-weight: 800;
  line-height: 1.11;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  position: relative;
  z-index: 2;
}

.conf-heading__image-block {
  width: 850px;
  aspect-ratio: 85/81;
  display: flex;
  position: absolute;
  right: -97px;
  top: -60px;
  z-index: 0;
}

.conf-heading__image {
  width: 100%;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
  position: relative;
  z-index: 2;
}

.conf-heading__tennis {
  width: 119px;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
  position: absolute;
  border-radius: 50%;
  box-shadow: -30px 44px 44px 0px var(--conf-heading-balls-shadow);
  top: 153px;
  left: 157px;
  z-index: 3;
}

.conf-heading__football {
  width: 206px;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
  border-radius: 50%;
  box-shadow: -30px 44px 44px 0px var(--conf-heading-balls-shadow);
  position: absolute;
  top: 285px;
  right: -72px;
  z-index: 3;
}

.conf-heading__basketball {
  width: 370px;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
  position: absolute;
  bottom: -148px;
  left: 0;
  z-index: 1;
}

.conf-heading__rectangle {
  width: 394px;
  height: 566px;
  transform: rotate(45deg);
  opacity: 0.1;
  background: var(--main-heading-rectangle-color);
  position: absolute;
  top: 397px;
  left: -23px;
  z-index: 0;
}

.conf-heading__circle {
  aspect-ratio: 1/1;
  border-radius: 50%;
  filter: blur(232px);
  position: absolute;
  z-index: 1;
}

.conf-heading__circle_size_small {
  width: 466px;
  background: var(--conf-heading-circle-color);
  top: 315px;
  right: -305px;
}

.conf-heading__circle_size_big {
  width: 602px;
  background: var(--main-heading-circle-color);
  top: 152px;
  left: 0;
}

@media screen and (max-width: 1200px) {
  .conf-heading__image-block {
    right: -120px;
  }
}

@media screen and (max-width: 1050px) {
  .conf-heading__image-block {
    right: -260px;
  }
}

@media screen and (max-width: 900px) {
  .conf-heading {
    padding: 0 26px;
  }

  .conf-heading__content {
    padding: 440px 0 40px;
  }

  .conf-heading__title {
    max-width: 378px;
    font-size: 36px;
    line-height: 1.1;
    letter-spacing: 1.44px;
  }

  .conf-heading__image-block {
    width: 625px;
    left: 22.38%;
    top: 2.5%;
  }

  .conf-heading__tennis {
    width: 12.28%;
    top: 20.05%;
    left: 20.39%;
  }

  .conf-heading__football {
    width: 33.77%;
    top: 44.47%;
    right: -2.85%;
  }

  .conf-heading__basketball {
    width: 230px;
    bottom: 141px;
    left: -115px;
  }

  .conf-heading__rectangle {
    width: 137.94%;
    height: 174.69%;
    top: 54.94%;
    left: -40%;
  }
}

@media screen and (max-width: 520px) {
  .conf-heading__image-block {
    width: 134.91%;
    left: unset;
    right: -31.95%;
  }

  .conf-heading__basketball {
    width: 40.83%;
    bottom: 25.22%;
    left: -20.41%;
  }

  .conf-heading__circle_size_small {
    display: none;
  }

  .conf-heading__circle_size_big {
    top: 78px;
    left: 264px;
  }
}
