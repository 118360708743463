.header {
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  background: var(--bg-with-opacity);
  backdrop-filter: blur(6.5px);
  box-sizing: border-box;
  padding: 10px 32px;
  transition: all 0.3s ease-in-out;
  z-index: 10;
}

.header__container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  position: relative;
}

.header__logo-link {
  display: flex;
}

.header__logo {
  width: 75px;
  height: 32px;
}

.header__logo-text {
  fill: var(--neutral-active);
}

.header__logo-fill {
  fill: var(--primary-active);
}

.header__menu-button {
  display: none;
}

.header__nav-list {
  display: flex;
  align-items: center;
  gap: 32px;
}

.header__nav-link {
  font-size: 12px;
  font-weight: 520;
  line-height: 1.5;
  position: relative;
  padding-bottom: 20px;
  transition: all 0.15s ease-in-out;
}

.header__nav-link:hover {
  opacity: 0.8;
}

.header__nav-link::before {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 2.5px;
  background: var(--primary-active);
  opacity: 0;
  transition: all 0.15s ease-in-out;
}

.header__nav-link_active {
  pointer-events: none;
  user-select: none;
}

.header__nav-link_active::before {
  opacity: 1;
}

.header__nav-link_disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.7;
}

.header__controls {
  display: flex;
  align-items: center;
  gap: 32px;
}

.header__lang-switcher-container {
  position: relative;
  z-index: 8;
}

.header__lang-switcher {
  display: flex;
  align-items: center;
  gap: 4px;
  transition: all 0.15s ease-in-out;
}

.header__lang-switcher:hover {
  opacity: 0.8;
}

.header__lang-switcher-text {
  font-size: 12px;
  font-weight: 520;
  line-height: 1.5;
}

.header__lang-switcher-arrow-container {
  width: 10px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__lang-switcher-arrow {
  width: 100%;
  height: 100%;
}

.header__lang-switcher-arrow-stroke {
  stroke: var(--neutral-active);
}

.header__lang-dropdown-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 9;
}

.header__lang-dropdown {
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  left: -12px;
  top: 24px;
  background: var(--secondary-background);
  border: 1px solid var(--neutral-selection);
  border-radius: 6px;
  box-sizing: border-box;
  padding: 12px;
  z-index: 10;
}

.header__lang-dropdown-button {
  font-size: 12px;
  font-weight: 520;
  line-height: 1.5;
  transition: all 0.15s ease-in-out;
}

.header__lang-dropdown-button:hover {
  opacity: 0.8;
}

.header__lang-dropdown-button_selected {
  color: var(--primary-active);
  pointer-events: none;
  user-select: none;
}

.header__join-button {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
  border-radius: 6px;
  background: var(--primary-active);
  box-sizing: border-box;
  padding: 0 14px;
  transition: all 0.15s ease-in-out;
}

.header__join-button:hover {
  opacity: 0.9;
}

@media screen and (max-width: 1050px) {
  .header__nav-list {
    gap: 24px;
  }

  .header__controls {
    gap: 24px;
  }
}

@media screen and (max-width: 900px) {
  .header {
    padding: 10px 16px;
  }

  .header__logo-link {
    position: absolute;
    left: calc(50% - 34px);
  }

  .header__logo {
    width: 68px;
    height: 26px;
  }

  .header__menu-button {
    width: 18px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header__menu-button-icon {
    width: 100%;
    height: 100%;
  }

  .header__menu-button-stroke {
    stroke: var(--neutral-active);
  }

  .header__nav {
    display: none;
  }

  .header__lang-switcher-container {
    display: none;
  }

  .header__join-button {
    height: 32px;
  }

  .header__join-button:hover {
    opacity: 1;
  }
}
