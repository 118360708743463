.footer {
  display: flex;
  align-items: center;
  gap: 4px;
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  padding: 8px 16px 60px;
}

.footer__text {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  opacity: 0.7;
}

.footer__text:first-child {
  border-right: 1px solid var(--neutral-active);
  padding-right: 4px;
}

@media (max-width: 900px) {
  .footer {
    padding: 0 16px 40px;
  }
}

@media (max-width: 450px) {
  .footer {
    flex-direction: column;
    gap: 0;
  }

  .footer__text {
    line-height: 1.33;
  }

  .footer__text:first-child {
    border-right: none;
    padding-right: 0;
  }
}
