.main-place {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  padding: 30px 32px;
}

.main-place__image {
  width: 100%;
  min-width: 2560px;
  height: 100%;
  min-height: 1591px;
  object-fit: cover;
  position: absolute;
  top: -650px;
  left: 0;
  z-index: -1;
}

.main-place__content {
  width: 100%;
  max-width: 1200px;
}

.main-place__block {
  max-width: 412px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background: rgba(var(--neutral-bg-with-opacity), 0.82);
  backdrop-filter: blur(7px);
  box-sizing: border-box;
  padding: 75px 40px 30px;
}

.main-place__logo {
  width: 190px;
  height: 52px;
}

.main-place__logo-fill {
  fill: var(--main-place-logo-color);
}

.main-place__title {
  width: 100%;
  color: var(--neutral-secondary-active);
  font-size: 26px;
  font-weight: 400;
  line-height: 1.15;
  margin-top: 75px;
}

.main-place__text {
  color: var(--neutral-secondary-active);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  margin-top: 12px;
}

.main-place__buttons {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(min-content, max-content));
  gap: 12px;
  margin-top: 40px;
}

@media screen and (max-width: 2190px) {
  .main-place__image {
    left: -365px;
  }
}

@media screen and (max-width: 900px) {
  .main-place {
    padding: 278px 12px 12px;
  }

  .main-place__image {
    width: 1799px;
    min-width: unset;
    height: 1118px;
    min-height: unset;
    top: unset;
    bottom: 0;
    left: -535px;
  }

  .main-place__block {
    max-width: 100%;
    border-radius: 12px;
    padding: 40px 20px 20px;
  }

  .main-place__title {
    font-size: 22px;
    line-height: 1.18;
    margin-top: 40px;
  }

  .main-place__text {
    font-size: 14px;
    line-height: 1.29;
    margin-top: 10px;
  }

  .main-place__buttons {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 20px;
  }
}

@media screen and (max-width: 600px) {
  .main-place__buttons {
    grid-template-columns: 35.28% 1fr;
  }
}
