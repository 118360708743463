.mobile-menu {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: translateX(-100%);
  z-index: 11;
  transition: all 0s ease-in-out 0.2s;
}

.mobile-menu_opened {
  transform: translateX(0);
  transition: all 0s linear;
}

.mobile-menu__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--primary-background-with-opacity);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  z-index: 10;
}

.mobile-menu__overlay_opened {
  opacity: 1;
}

.mobile-menu__container {
  width: 100%;
  max-width: 450px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: var(--primary-background);
  background-image: url('../../../assets/images/header/bg-img.webp');
  background-size: 371px 540px;
  background-position: top right;
  background-repeat: no-repeat;
  overflow: hidden;
  box-sizing: border-box;
  padding: 12px 60px 0;
  transform: translateX(-100%);
  transition: transform 0.2s ease-in-out;
  z-index: 11;
}

.mobile-menu__container_opened {
  transform: translateX(0);
}

.mobile-menu__close-button {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 14px;
  left: 16px;
}

.mobile-menu__close-icon {
  width: 100%;
  height: 100%;
}

.mobile-menu__close-icon-stroke {
  stroke: var(--neutral-active);
}

.mobile-menu__logo-link {
  display: flex;
}

.mobile-menu__logo {
  width: 68px;
  height: 26px;
}

.mobile-menu__logo-text {
  fill: var(--neutral-active);
}

.mobile-menu__logo-fill {
  fill: var(--primary-active);
}

.mobile-menu__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: auto;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 62px 0 40px;
}

.mobile-menu__content::-webkit-scrollbar {
  display: none;
}

.mobile-menu__content-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: auto;
}

.mobile-menu__nav {
  width: 100%;
}

.mobile-menu__nav-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.mobile-menu__nav-link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  font-size: 22px;
  font-weight: 300;
  line-height: 1.18;
}

.mobile-menu__nav-link::after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background: var(--primary-active);
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}

.mobile-menu__nav-link_active::after {
  opacity: 1;
}

.mobile-menu__nav-link_disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.7;
}

.mobile-menu__block {
  width: 100%;
  box-sizing: border-box;
  padding: 24px 0;
  border-bottom: 1px solid var(--primary-selection-with-opacity);
}

.mobile-menu__block:first-of-type {
  border-top: 1px solid var(--primary-selection-with-opacity);
  margin-top: 40px;
}

.mobile-menu__lang-switcher-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: 12px;
}

.mobile-menu__lang-switcher {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 12px;
  font-weight: 520;
  line-height: 1.5;
  opacity: 0.7;
  border: 1px solid transparent;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 7px 0;
  transition: all 0.15s ease-in-out;
}

.mobile-menu__lang-switcher_selected {
  border: 1px solid var(--secondary-active);
  opacity: 1;
}

.mobile-menu__location {
  display: flex;
  justify-content: center;
  gap: 6px;
}

.mobile-menu__location-icon {
  width: 18px;
  height: 18px;
}

.mobile-menu__location-icon-stroke {
  stroke: var(--primary-active);
}

.mobile-menu__location-text-block {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.mobile-menu__location-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.12;
}

.mobile-menu__location-text_type_subtitle {
  font-size: 14px;
  line-height: 1;
  opacity: 0.6;
}

.mobile-menu__join-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 550;
  line-height: 1.25;
  border-radius: 8px;
  background: var(--primary-active);
  box-sizing: border-box;
  padding: 12px 0;
  margin-top: 24px;
}

.mobile-menu__footer-text {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  opacity: 0.7;
  margin-top: 16px;
}
