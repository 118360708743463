.partners {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    box-sizing: border-box;
    padding-bottom: 140px;
}

.partners__img-container {
    width: 422px;
    height: fit-content;
    display: flex;
    position: absolute;
    right: 112px;
    top: 142px;
    z-index: 1;
}

.partners__img {
    width: 100%;
    object-fit: contain;
    pointer-events: none;
    user-select: none;
    position: relative;
    z-index: 3;
}

.partners__img-bg {
    width: 150.24%;
    object-fit: contain;
    pointer-events: none;
    user-select: none;
    position: absolute;
    top: -66.86%;
    left: -23.7%;
    z-index: 2;
}

.partners__circle {
    width: 104.03%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    opacity: 0.6;
    background: var(--conf-heading-circle-color);
    filter: blur(232px);
    position: absolute;
    top: -22.78%;
    left: -4.03%;
    z-index: 0;
}

.partners__img-football {
    width: 235px;
    object-fit: contain;
    position: absolute;
    right: -237px;
    top: 275px;
    pointer-events: none;
    user-select: none;
    z-index: 2;
}

.partners__img-basketball {
    width: 257px;
    object-fit: contain;
    position: absolute;
    top: -84px;
    right: 530px;
    pointer-events: none;
    user-select: none;
    z-index: 2;
}

.partners__content {
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    padding: 0 32px;
    margin-top: -78px;
    z-index: 1;
}

.partners__all {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    gap: 60px;
    position: relative;
}

.partners__grid-partners-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
}

.partners__grid-partners {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, minMax(0, 1fr));
    column-gap: 8px;
    row-gap: 8px;

    grid-template-rows: max-content;
    grid-auto-rows: max-content;
}

.partners__grid-partners__title {
    color: var(--neutral-active);
    
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: 52px;
    /* 123.81% */
    letter-spacing: 1.68px;
    text-align: left;
}

@media (max-width: 1200px) {
    .partners__img-football {
        right: -157px;
    }

    .partners__grid-partners {
        grid-template-columns: repeat(3, minMax(0, 1fr));
    }
}

@media (max-width: 1030px) {
    .partners__img-container {
        width: 360px;
        right: 62px;
        top: 82px;
    }
}

@media (max-width:900px) {
    .partners {
        padding-bottom: 112px;
    }

    .partners__img-container {
        width: 260px;
        right: unset;
        top: 139px;
    }

    .partners__circle {
        display: none;
    }

    .partners__img-football {
        width: 42.01%;
        max-width: 280px;
        top: 319px;
        right: -26.33%;
    }

    .partners__img-basketball {
        width: 39.64%;
        max-width: 257px;
        left: -19.82%;
        top: 0;
    }

    .partners__content {
        padding: 0 26px;
        margin-top: 40px;
    }

    .partners__grid-partners {
        grid-template-columns: repeat(2, minMax(0, 1fr));
    }
}

@media (max-width: 600px) {
    .partners__grid-partners {
        grid-template-columns: repeat(2, minMax(0, 1fr));
    }

    .partners__grid-partners_general {
        grid-template-columns: repeat(1, minMax(0, 1fr));
    }
}
