.sponsors {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  padding-bottom: 140px;
}

.sponsors__img {
  width: 993px;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
  position: absolute;
  right: -274px;
  top: -204px;
  z-index: 1;
}

.sponsors__img_type_mobile {
  display: none;
}

.sponsors__circle {
  width: 466px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  opacity: 0.6;
  background: var(--conf-heading-circle-color);
  filter: blur(232px);
  position: absolute;
  top: 108px;
  right: 66px;
  z-index: 0;
}

.sponsors__content {
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding: 0 32px;
  margin-top: -78px;
  z-index: 1;
}

.sponsors__container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
}

.sponsors__title-block {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-top: 60px;
}

.sponsors__type-icon {
  width: 52px;
  aspect-ratio: 1/1;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
}

.sponsors__title-box {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 16px;
}

.sponsors__type-title {
  font-size: 42px;
  font-weight: 500;
  line-height: 1.24;
  letter-spacing: 1.68px;
}

.sponsors__subtitle-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  position: relative;
  top: -4px;
  border-radius: 6px;
  border: 1px solid rgba(var(--neutral-bg-with-opacity), 0.6);
  padding: 2px 7px;
}

.sponsors__subtitle-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.14;
}

.sponsors__subtitle-text_type_opacity {
  opacity: 0.6;
}

.sponsors__info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
}

@media screen and (max-width: 1180px) {
  .sponsors__img {
    right: -404px;
  }
}

@media screen and (max-width: 900px) {
  .sponsors {
    padding-bottom: 112px;
  }

  .sponsors__img {
    display: none;
  }

  .sponsors__img_type_mobile {
    width: 652px;
    display: block;
    right: unset;
    top: -6px;
    margin-left: -70px;
  }

  .sponsors__circle {
    display: none;
  }

  .sponsors__content {
    padding: 0 26px;
    margin-top: 40px;
  }

  .sponsors__title-block {
    gap: 8px;
    margin-top: 30px;
  }

  .sponsors__type-icon {
    width: 30px;
  }

  .sponsors__type-title {
    font-size: 26px;
    font-weight: 500;
    line-height: 1.15;
    letter-spacing: 1.04px;
  }

  .sponsors__subtitle-box {
    top: -2px;
    padding: 3px 5px;
  }

  .sponsors__subtitle-text {
    font-size: 12px;
    line-height: 1.17;
  }

  .sponsors__info {
    margin-top: 30px;
  }
}
