.popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-background-with-opacity);
  box-sizing: border-box;
  padding: 26px;
  visibility: hidden;
  opacity: 0;
  z-index: 100;
  transition: visibility 0.3s ease-in-out, opacity 0.2s ease-in-out;
}

.popup_opened {
  visibility: visible;
  opacity: 1;
}

.popup__container {
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  position: relative;
  border-radius: 16px;
  border: 1px solid var(--neutral-selection);
  background: var(--secondary-background);
  box-sizing: border-box;
  padding: 24px 30px;
  z-index: 100;
}

.popup__close-button {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 12px;
  right: 12px;
}

.popup__close-icon {
  width: 100%;
  height: 100%;
}

.popup__logo {
  width: 75px;
  height: 32px;
}

.popup__logo-text {
  fill: var(--neutral-active);
}

.popup__logo-fill {
  fill: var(--primary-active);
}
