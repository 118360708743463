.leader {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 32px;
}

.leader__content {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  gap: 60px;
  position: relative;
  box-sizing: border-box;
  padding: 120px 0;
}

.leader__title-block {
  max-width: 351px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
  z-index: 1;
}

.leader__title {
  color: var(--primary-active);
  font-size: 62px;
  font-weight: 500;
  line-height: 1.06;
}

.leader__btn {
  position: relative;
  z-index: 1;
}

.leader__btn_type_mobile {
  display: none !important;
}

.leader__cards-list {
  width: 100%;
  max-width: 700px;
  display: grid;
  grid-template-columns: repeat(2, minmax(min-content, max-content));
  justify-content: space-between;
  gap: 50px;
  position: relative;
  z-index: 1;
}

.leader__card {
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.leader__card-heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.leader__card-title {
  font-size: 26px;
  font-weight: 600;
  line-height: 1.23;
}

.leader__card-icon {
  width: 60px;
  height: 60px;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
}

.leader__card-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  opacity: 0.7;
}

.leader__ball {
  width: 432px;
  object-fit: contain;
  mix-blend-mode: luminosity;
  pointer-events: none;
  user-select: none;
  position: absolute;
  top: 71px;
  left: -272px;
  z-index: 0;
}

.leader__circle {
  aspect-ratio: 1/1;
  border-radius: 50%;
  filter: blur(232px);
  position: absolute;
  z-index: -1;
}

.leader__circle_size_m {
  width: 500px;
  background: var(--main-sponsorship-circle-color);
  top: -159px;
  right: -217px;
}

.leader__circle_size_l {
  width: 602px;
  opacity: 0.2;
  background: var(--main-leader-circle-color);
  bottom: -112px;
  right: -216px;
}

.leader__ellipse {
  position: absolute;
  border-radius: 100%;
}

.leader__ellipse_size_s {
  width: 306px;
  height: 129px;
  background: var(--main-leader-small-ellipse-color);
  filter: blur(90px);
  bottom: 43px;
  left: -204px;
  z-index: -1;
}

.leader__ellipse_size_l {
  width: 938px;
  height: 284px;
  background: var(--main-leader-big-ellipse-color);
  filter: blur(202px);
  bottom: -112px;
  left: -520px;
  z-index: -2;
}

@media screen and (max-width: 1100px) {
  .leader__content {
    gap: 40px;
  }

  .leader__title-block {
    max-width: 300px;
  }

  .leader__title {
    font-size: 52px;
  }

  .leader__cards-list {
    max-width: 580px;
    gap: 30px;
  }

  .leader__card {
    max-width: 260px;
  }

  .leader__card-text {
    font-size: 14px;
    line-height: 1.29;
  }

  .leader__ball {
    left: -202px;
  }

  .leader__ellipse_size_s {
    left: -134px;
  }

  .leader__ellipse_size_l {
    left: -450px;
  }
}

@media screen and (max-width: 900px) {
  .leader {
    padding: 0 26px;
  }

  .leader__content {
    flex-direction: column;
    gap: 30px;
    padding: 110px 0 40px;
  }

  .leader__title-block {
    max-width: 320px;
  }

  .leader__title {
    font-size: 34px;
    line-height: 1.11;
  }

  .leader__btn {
    display: none !important;
  }

  .leader__btn_type_mobile {
    max-width: 100% !important;
    display: flex !important;
  }

  .leader__cards-list {
    max-width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }

  .leader__card {
    max-width: 100%;
    gap: 10px;
  }

  .leader__card-title {
    font-size: 22px;
    line-height: 1.27;
  }

  .leader__card-icon {
    width: 56px;
    height: 56px;
  }

  .leader__ball {
    width: 300px;
    top: -99px;
    left: calc(50% - 150px);
  }

  .leader__circle_size_m {
    top: -381px;
    right: calc(50% - 250px);
  }

  .leader__circle_size_l {
    bottom: calc(50% - 301px);
    left: -384px;
  }

  .leader__ellipse_size_s {
    display: none;
  }

  .leader__ellipse_size_l {
    width: 781px;
    height: 236px;
    bottom: -84px;
    left: -502px;
  }
}

@media screen and (max-width: 500px) {
  .leader__cards-list {
    display: flex;
    flex-direction: column;
  }
}
