.conf-info {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background: var(--primary-background);
  box-sizing: border-box;
  padding: 0 32px;
}

.conf-info__content {
  width: 100%;
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(2, minmax(min-content, max-content));
  justify-content: space-between;
  gap: 40px;
  position: relative;
  background-image: url(../../../assets/images/main/join/bg-img.webp);
  background-size: 446px 488px;
  background-position: bottom -130px left 60%;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding: 120px 0;
}

.conf-info__block {
  max-width: 550px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  z-index: 1;
}

.conf-info__title {
  font-size: 42px;
  font-weight: 500;
  line-height: 1.24;
  letter-spacing: 1.68px;
}

.conf-info__text {
  max-width: 450px;
  color: var(--primary-active);
  font-size: 26px;
  font-weight: 400;
  line-height: 1.3;
}

.conf-info__block-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.12;
}

.conf-info__list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.conf-info__list-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  opacity: 0.7;
}

.conf-info__circle {
  aspect-ratio: 1/1;
  border-radius: 50%;
  background: var(--main-join-circle-color);
  position: absolute;
  z-index: 0;
}

.conf-info__circle_size_small {
  width: 370px;
  top: -222px;
  left: 0;
  opacity: 0.3;
}

.conf-info__circle_size_big {
  width: 630px;
  top: -510px;
  right: -120px;
  opacity: 0.6;
}

@media screen and (max-width: 1200px) {
  .conf-info__circle_size_big {
    right: -143px;
  }
}

@media screen and (max-width: 1050px) {
  .conf-info__circle_size_big {
    right: -283px;
  }
}

@media screen and (max-width: 900px) {
  .conf-info {
    padding: 0;
  }

  .conf-info__content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    background-size: 285px 312px;
    background-position: top 175px right -59px;
    padding: 40px 26px;
  }

  .conf-info__block {
    max-width: 100%;
  }

  .conf-info__title {
    font-size: 24px;
    letter-spacing: 0.96px;
  }

  .conf-info__text {
    max-width: 100%;
    font-size: 20px;
  }

  .conf-info__list-text {
    font-size: 14px;
    line-height: 1.29;
  }

  .conf-info__circle {
    display: none;
  }
}
