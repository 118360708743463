.agenda {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    box-sizing: border-box;
    padding-bottom: 140px;
}

.agenda__img-cards {
    width: 856px;
    object-fit: contain;
    position: absolute;
    top: -45px;
    right: -181px;
    pointer-events: none;
    user-select: none;
    z-index: 1;
}

.agenda__content {
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    padding: 0 32px;
    margin-top: -78px;
    z-index: 1;
}

.agenda__table-day {
    position: relative;
    z-index: 6;
}

.agenda__table-day__day-block {
    width: 100%;
    max-width: 1200px;
}

.agenda__table-day__day-block:last-of-type {
    margin-top: 120px;
}

.agenda__table-day__line {
    display: grid;
    grid-template-columns: 140px 1fr 1fr;
    column-gap: 138px;
    padding: 40px 0;
    border-top: 1px solid var(--primary-selection);
    box-sizing: border-box;
}

.agenda__table-day__line:last-child {
    border-bottom: 1px solid var(--primary-selection);
}

.agenda__table-day__time-place-box {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.agenda__table-day__title {
    color: var(--primary-active);
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 66px;
    /* 137.5% */
    padding-bottom: 40px;
}

.agenda__table-day__time {
    color: var(--neutral-active);
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 109.091% */
    white-space: nowrap;
}

.agenda__table-day__place {
    color: var(--neutral-active);

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 125% */

    display: flex;

}

.agenda__table-day__text {
    color: var(--neutral-active);

    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 180% */
}

.agenda__table-day__subtext-box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.agenda__table-day__subtext {
    color: var(--neutral-active);

    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    /* 128.571% */
    display: grid;
    grid-template-columns: 4px 1fr;
    column-gap: 6px;
}

.agenda__table-day__subtext p {
    opacity: 0.7;
}

.agenda__table-day__subtext__circle {
    border-radius: 50%;
    width: 4px;
    height: 4px;
    background-color: var(--neutral-active);
    opacity: 1 !important;
    margin-top: 7px;
}

.agenda__table-day__speakers__box {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.agenda__table-day__speakers__title {
    color: var(--neutral-active);

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    /* 300% */
    opacity: 0.7;
}

.agenda__table-day__speakers__author {
    color: var(--neutral-active);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.agenda__table-day__speakers__img {
    border-radius: 50%;
    width: 42px;
    height: 42px;
}

.agenda__table-day__speakers__img_placeholder {
    opacity: 0.3;
    background: #D9D9D9;
    border-radius: 50%;
    width: 42px;
    height: 42px;
}

.agenda__table-day__speakers-line {
    display: grid;
    grid-template-columns: 42px 1fr;
    grid-template-rows: max-content;
    column-gap: 12px;
    align-items: center;
}

.agenda__table-day__speakers__author-img {
    border-radius: 50%;
    width: 42px;
    height: 42px;
    pointer-events: none;
    user-select: none;

}

@media (max-width:1200px) {
    .agenda__img-cards {
        right: -331px;
    }

    .agenda__table-day__line {
        column-gap: 80px;
    }
}

@media (max-width:900px) {
    .agenda {
        padding-bottom: 112px;
    }

    .agenda__img-cards {
        width: 560px;
        top: 0;
        right: unset;
        margin-left: -50px;
    }

    .agenda__content {
        padding: 0 26px;
        margin-top: 0;
    }

    .agenda__table-day__title {
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;

        margin-top: 40px;
    }

    .agenda__table-day__time {
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        /* 122.222% */
    }

    .agenda__table-day__line {
        grid-template-columns: 1fr;
        width: 100vw;
        padding: 40px 26px 40px 26px;
        margin-left: -26px;
    }

    .agenda__table-day__day-block:last-of-type {
        margin-top: 18px;
    }

    .agenda__table-day__time-place-box {
        gap: 7px;
    }

    .agenda__table-day__place {
        white-space: nowrap;
        opacity: 0.7;
        border-bottom: 1px solid rgba(var(--primary-selection-rgb), 0.5);
        width: 100%;
        padding-bottom: 16px;

        color: var(--neutral-active);

        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        /* 128.571% */
        gap: 6px;
    }

    .agenda__table-day__text {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        /* 125% */
    }

    .agenda__table-day__speakers__box {
        column-gap: 40px;

        row-gap: 16px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }



    .agenda__table-day__speakers__title {
        opacity: 1;
    }
}