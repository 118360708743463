.page-heading {
  width: 100%;
  display: flex;
  justify-content: center;
  background: var(--website-background);
  box-sizing: border-box;
  padding: 0 32px;
  position: relative;
  z-index: 0;
}

.page-heading__content {
  width: 100%;
  max-width: 1200px;
  min-height: 648px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 198px;
}

.page-heading__content_type_no-btn {
  padding-bottom: 330px;
}

.page-heading__title-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
  z-index: 5;
}

.page-heading__title {
  max-width: 600px;
  font-size: 60px;
  font-weight: 800;
  line-height: 1.1;
  letter-spacing: 2.4px;
  text-transform: uppercase;
}

.page-heading__bg {
  width: 2080px;
  height: 648px;
  pointer-events: none;
  user-select: none;
  position: absolute;
  top: 0;
  left: -612px;
  z-index: -1;
}

.page-heading__bg_type_mobile {
  display: none;
}

.page-heading__gradient {
  display: none;
}

@media screen and (max-width: 900px) {
  .page-heading {
    padding: 0 26px;
  }

  .page-heading__content {
    min-height: 590px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 0;
  }

  .page-heading__content_type_no-btn {
    padding-bottom: 90px;
  }

  .page-heading__title-block {
    gap: 26px;
  }

  .page-heading__title {
    font-size: 36px;
    letter-spacing: 1.44px;
  }

  .page-heading__bg {
    display: none;
  }

  .page-heading__bg_type_mobile {
    width: 921px;
    height: 614px;
    display: block;
    top: -54px;
    left: unset;
    mix-blend-mode: color-dodge;
  }

  .page-heading__gradient {
    width: 100vw;
    height: 428px;
    display: block;
    position: absolute;
    bottom: 30px;
    left: -26px;
    background: linear-gradient(
      0deg,
      var(--website-background) 0%,
      rgba(var(--bg-gradient), 0.5) 37.51%,
      rgba(var(--bg-gradient), 0) 92.76%
    );
    z-index: 0;
  }
}

@media (max-width: 590px) {
  .page-heading__btn {
    max-width: 100%;
  }
}
