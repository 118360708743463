.sponsorship {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background: var(--primary-background);
  box-sizing: border-box;
  padding: 0 32px;
}

.sponsorship__content {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  position: relative;
  box-sizing: border-box;
  padding: 104px 0;
}

.sponsorship__title-block {
  max-width: 377px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
  z-index: 1;
}

.sponsorship__title {
  font-size: 48px;
  font-weight: 500;
  line-height: 1.04;
  letter-spacing: 1.92px;
}

.sponsorship__text {
  max-width: 300px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  opacity: 0.7;
  position: relative;
  z-index: 1;
}

.sponsorship__text_type_mobile {
  display: none;
}

.sponsorship__img-block {
  width: 300px;
  height: 100%;
  display: flex;
  align-self: center;
  position: relative;
  margin-top: -24px;
  z-index: 1;
}

.sponsorship__img {
  width: 100%;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.sponsorship__img-bg {
  width: 133.33%;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
  position: absolute;
  top: -45.87%;
  left: -6.33%;
  z-index: 0;
}

.sponsorship__circle {
  width: 602px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background: var(--main-sponsorship-circle-color);
  filter: blur(232px);
  position: absolute;
  top: -436px;
  right: calc(50% - 301px);
  z-index: 0;
}

@media screen and (max-width: 1100px) {
  .sponsorship__title-block {
    max-width: 300px;
    gap: 20px;
  }

  .sponsorship__title {
    font-size: 38px;
    letter-spacing: 1.52px;
  }

  .sponsorship__text {
    max-width: 260px;
    font-size: 14px;
    line-height: 1.29;
  }

  .sponsorship__img-block {
    width: 240px;
  }

  .sponsorship__img-bg {
    top: -35%;
  }
}

@media screen and (max-width: 900px) {
  .sponsorship {
    padding: 0 26px;
  }

  .sponsorship__content {
    padding: 50px 0 40px;
  }

  .sponsorship__title-block {
    max-width: 65%;
    gap: 0;
  }

  .sponsorship__title {
    font-size: 36px;
    line-height: 1.11;
    letter-spacing: 1.44px;
  }

  .sponsorship__text {
    display: none;
  }

  .sponsorship__text_type_mobile {
    display: block;
    max-width: unset;
    padding-top: 12px;
  }

  .sponsorship__btn {
    margin-top: 30px;
  }

  .sponsorship__img-block {
    min-width: 262px;
    height: auto;
  }

  .sponsorship__img {
    position: relative;
  }
}

@media screen and (max-width: 640px) {
  .sponsorship__content {
    flex-direction: column-reverse;
    align-items: center;
    gap: 10px;
  }

  .sponsorship__title-block {
    max-width: unset;
  }

  .sponsorship__btn {
    max-width: 100% !important;
  }

  .sponsorship__img-block {
    margin-top: 0;
  }

  .sponsorship__img-bg {
    width: 345px;
    top: unset;
    bottom: 0;
    left: -29px;
  }

  .sponsorship__circle {
    top: -212px;
    right: -446px;
  }
}
