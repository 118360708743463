.exhibition {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    box-sizing: border-box;
    padding-bottom: 140px;
}

.exhibition__title {
    max-width: 67%;
}

.exhibition__heading-img {
    max-width: 1122px;
    object-fit: contain;
    pointer-events: none;
    user-select: none;
    position: absolute;
    top: -238px;
    right: -232px;
    z-index: 1;
}

.exhibition__heading-img_type_mobile {
    display: none;
}

.exhibition__content {
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    padding: 0 32px;
    margin-top: -152px;
    z-index: 1;
}

.exhibition__map-block {
    width: 100%;
    max-width: 1200px;
    display: grid;
    grid-template-columns: 1fr 268px;
    gap: 40px;
}

.exhibition__img-map {
    max-width: 892px;
    width: 100%;
    pointer-events: none;
    user-select: none;

}

.exhibition__legend {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 86px;
    padding-bottom: 40px;
}

.exhibition__legend-line {
    display: flex;
    gap: 12px;
}

.exhibition__legend-line__color-box {
    width: 26px;
    height: 26px;
}

.exhibition__legend-text {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 40px 0;
    border-top: 1px solid var(--primary-selection);
    border-bottom: 1px solid var(--primary-selection);
}

.exhibition__legend-text__title {
    color: var(--neutral-active);
    
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 115.385% */
}

.exhibition__legend-text__text {
    color: var(--neutral-active);
    
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 125% */
    opacity: 0.7;
}

.exhibition__place-box {
    display: flex;
    gap: 6px;
    padding-top: 40px;
}

.exhibition__place-box__text {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.exhibition__legend-text__rixos {
    color: var(--neutral-active);
    
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 112.5% */
}

.exhibition__legend-text__kz {
    color: var(--neutral-active);
    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    /* 100% */
    opacity: 0.6;
}

.exhibition__logo {
    display: none;
}

@media (max-width: 1200px) {
    .exhibition__heading-img {
        max-height: 800px;
        top: -120px;
        right: -176px;
    }
}

@media (max-width: 900px) {
    .exhibition {
        padding-bottom: 112px;
    }

    .exhibition__title {
        max-width: 100%;
    }

    .exhibition__heading-img {
        display: none;
    }

    .exhibition__heading-img_type_mobile {
        width: 454px;
        display: block;
        top: 40px;
        right: unset;
        margin-right: -50px;
    }

    .exhibition__content {
        padding: 0 26px;
        margin-top: 40px;
    }

    .exhibition__map-block {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .exhibition__legend {
        margin-top: 30px;
        flex-direction: row;
        padding-bottom: 30px;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        gap: 31px;
    }

    .exhibition__legend-text__title {
        font-size: 22px;
        font-weight: 400;
        line-height: 26px;
        /* 118.182% */
    }

    .exhibition__legend-text__text {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        /* 128.571% */
    }

    .exhibition__legend-line {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        text-align: center;

        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        /* 128.571% */
    }

    .exhibition__legend-text {
        padding: 30px 0;
    }

    .exhibition__place-box {
        border-bottom: 1px solid var(--primary-selection);
        padding-bottom: 30px;
        padding-top: 30px;
    }

    .exhibition__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
    }
}
