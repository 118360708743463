.button {
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 12px;
  border: 1px solid var(--neutral-active);
  backdrop-filter: blur(6.5px);
  box-sizing: border-box;
  padding: 0 24px;
  transition: opacity 0.15s ease-in-out;

  white-space: nowrap;
  font-size: 20px;
  font-weight: 550;
  line-height: 1.3;
}

.button_type_card {
  height: 46px;
  border-radius: 8px;
  border: 1px solid var(--secondary-active);
  padding: 0 12px;

  color: var(--secondary-active);
  font-size: 14px;
}

.button_type_color {
  background: var(--secondary-active);
  color: var(--neutral-active);
  border: none;
  padding: 0 40px;
}

.button_size_big {
  width: unset;
  height: 58px;
}

.button_size_small {
  height: 46px;
  border-radius: 8px;
  font-size: 14px;
}

.button_width_restricted {
  max-width: 252px;
}

.button:hover {
  opacity: 0.8;
}

@media screen and (max-width: 900px) {
  .button {
    height: 44px;
    border-radius: 8px;
    font-size: 16px;
    line-height: 1.25;
  }

  .button_type_card {
    height: 32px;
    border-radius: 6px;
    font-size: 12px;
    line-height: 1.33;
  }

  .button_size_small {
    font-size: 14px;
  }

  .button:hover {
    opacity: 1;
  }
}
