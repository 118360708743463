@import url('./assets/fonts/Outfit/stylesheet.css');

body {
  margin: 0;
  font-family: 'Outfit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--website-background);
}

code {
  font-family: 'Outfit', source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}

p, h1, h2, h3, h4, h5, h6, blockquote, span {
  padding: 0;
  margin: 0;
  color: var(--neutral-active);
  font-style: normal;
  white-space: pre-line;
}

a {
  text-decoration: none;
  color: var(--neutral-active);
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button {
  font-family: 'Outfit', sans-serif;
  color: var(--neutral-active);
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: all 0.2s linear;
}

ul, ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

input, textarea, label {
  font-family: 'Outfit', sans-serif;
  color: var(--neutral-active);
  background-color: transparent;
  border: none;
  outline: transparent;
  padding: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  font-family: 'Outfit', sans-serif;
  -webkit-box-shadow: 0 0 0 500px var(--website-background) inset !important;
  /* можно изменить на любой вариант цвета */
  -webkit-text-fill-color: var(--neutral-active) !important;
  background-color: var(--website-background) !important;
  background-clip: content-box !important;
  position: relative;
  z-index: 0;
}

:root {
  --website-background: #0D0055;
  --bg-with-opacity: rgba(11, 0, 71, 0.70);
  --neutral-bg-with-opacity: 255, 255, 255;

  --neutral-active: #FFFFFF;
  --neutral-secondary-active: #1E2444;
  --neutral-selection: #211986;
  --neutral-background: #000000;

  --primary-active: #FF921B;
  --primary-selection: #4E467A;
  --primary-selection-rgb: 78, 70, 122;
  --primary-selection-with-opacity: rgba(78, 70, 122, 0.70);
  --primary-background: #090040;
  --primary-background-with-opacity: rgba(9, 0, 64, 0.80);

  --secondary-active: #0045CE;
  --secondary-selection: #847FA0;
  --secondary-background: #0D075C;

  --link-color: #4483FF;
  --bg-gradient: 13, 1, 88;
  --box-shadow: #0D0055;

  --main-heading-rectangle-color: #4053FF;
  --main-heading-circle-color: #0275C8;
  --main-join-circle-color: #0E005E;
  --main-place-logo-color: #897869;
  --main-sponsorship-circle-color: #364BB9;
  --main-leader-circle-color: #9402C8;
  --main-leader-big-ellipse-color: #0036C1;
  --main-leader-small-ellipse-color: #1B0646;
  --main-about-scheme-shadow: #06002D;
  --main-show-circle-color: #000C79;

  --conf-heading-balls-shadow: rgba(13, 0, 85, 0.60);
  --conf-heading-circle-color: #042BFB;

  --select-border: #6B9DFF;
  --select-dropdown: #06014d;
}