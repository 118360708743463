.about {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background: var(--primary-background);
  box-sizing: border-box;
  padding: 0 32px;
}

.about__container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  box-sizing: border-box;
  padding: 40px 0;
}

.about__content {
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  position: relative;
}

.about__text-block {
  width: 30%;
  max-width: 287px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: -6px;
}

.about__title-box {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.about__title {
  font-size: 26px;
  font-weight: 400;
  line-height: 1.15;
}

.about__text {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  opacity: 0.7;
}

.about__location {
  display: flex;
  gap: 6px;
}

.about__location-icon {
  width: 18px;
  height: 18px;
}

.about__location-icon-stroke {
  stroke: var(--primary-active);
}

.about__location-text-block {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.about__location-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.12;
}

.about__location-subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  opacity: 0.6;
}

.about__btn {
  max-width: 200px;
}

.about__img-block {
  width: 592px;
  display: flex;
  position: relative;
}

.about__scheme {
  width: 100%;
  object-fit: contain;
  position: relative;
  z-index: 1;
}

.about__scheme-bg {
  width: 114.02%;
  height: 208.48%;
  transform: rotate(-45deg);
  position: absolute;
  left: 40.54%;
  top: 8.7%;
  z-index: 0;
}

.about__scheme-bg-fill {
  fill: var(--main-about-scheme-shadow);
}

@media screen and (max-width: 900px) {
  .about {
    padding: 0 26px;
  }

  .about__text-block {
    width: 40%;
    gap: 30px;
    margin-top: 0;
  }

  .about__title-box {
    gap: 10px;
  }

  .about__text {
    font-size: 14px;
    line-height: 1.29;
  }
}

@media screen and (max-width: 750px) {
  .about__content {
    flex-direction: column;
  }

  .about__text-block {
    width: 100%;
    max-width: 100%;
  }

  .about__btn {
    max-width: 100%;
  }

  .about__img-block {
    width: 100%;
  }
}
