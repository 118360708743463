.info-block {
  width: 100%;
  border-radius: 12px;
  background: rgba(var(--neutral-bg-with-opacity), 0.05);
}

.info-block__btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 24px 20px 24px 30px;
}

.info-block__title {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.16;
}

.info-block__arrow-wrapper {
  width: 28px;
  height: 28px;
}

.info-block__arrow {
  width: 100%;
  height: 100%;
}

.info-block__arrow-stroke {
  stroke: var(--neutral-active);
}

.info-block__content {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px 40px;
  border-top: 1px solid var(--primary-selection);
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 30px;
}

.info-block__content-item {
  position: relative;
  box-sizing: border-box;
  padding-left: 20px;
}

.info-block__content-item::before {
  content: '';
  width: 7px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background: var(--neutral-active);
  position: absolute;
  left: 0;
  top: 8px;
}

.info-block__content-text {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.25;
  opacity: 0.7;
}

@media (max-width: 900px) {
  .info-block__btn {
    padding: 20px 18px;
  }

  .info-block__title {
    font-size: 20px;
    line-height: 1.2;
  }

  .info-block__arrow-wrapper {
    width: 24px;
    height: 24px;
  }

  .info-block__content {
    grid-template-columns: 1fr;
    gap: 12px;
    padding-left: 18px;
    padding-right: 18px;
  }

  .info-block__content-item {
    padding-left: 10px;
  }

  .info-block__content-item::before {
    width: 4px;
  }

  .info-block__content-text {
    font-size: 14px;
    line-height: 1.29;
  }
}
