.main-partners {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 32px;
}

.main-partners__content {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding: 80px 0 160px;
}

.main-partners__title {
  font-size: 62px;
  font-weight: 500;
  line-height: 1.06;
}

.main-partners__list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 170px;
  grid-auto-rows: 170px;
  gap: 20px;
  margin-top: 60px;
}

.main-partners__card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 18px;
  background: rgba(var(--neutral-bg-with-opacity), 0.05);
  box-sizing: border-box;
  padding: 30px 20px 0;
}

.main-partners__card-title {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  opacity: 0.7;
}

.main-partners__card-icon {
  width: 100%;
  max-width: 184px;
  margin: auto 0;
}

.main-partners__card-icon-fill {
  fill: var(--secondary-selection);
}

.main-partners__card-icon-stroke {
  stroke: var(--secondary-selection);
}

.main-partners__btn {
  min-width: 224px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 550;
  line-height: 1.25;
  border-radius: 8px;
  background: rgba(var(--neutral-bg-with-opacity), 0.1);
  box-sizing: border-box;
  padding: 13px 55px;
  margin-top: 16px;
  transition: background 0.15s ease-in-out;
}

.main-partners__btn:hover {
  background: rgba(var(--neutral-bg-with-opacity), 0.15);
}

.main-partners__btn_type_mobile {
  display: none;
}

.main-partners__btn-icon {
  width: 20px;
  height: 20px;
}

.main-partners__btn-icon-fill {
  fill: var(--primary-active);
}

@media screen and (max-width: 1100px) {
  .main-partners__list {
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
  }

  .main-partners__btn {
    display: none;
    min-width: unset;
  }
  

  .main-partners__btn_type_mobile {
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-radius: 18px;
    padding: 0;
    margin-top: 0;
  }

  .main-partners__btn-icon {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 900px) {
  .main-partners {
    padding: 0 26px;
  }

  .main-partners__content {
    padding: 40px 0 112px;
  }

  .main-partners__title {
    font-size: 36px;
    line-height: 1.11;
    letter-spacing: 1.44px;
  }

  .main-partners__list {
    grid-template-rows: 100px;
    grid-auto-rows: 100px;
    gap: 6px;
    margin-top: 20px;
  }

  .main-partners__card {
    border-radius: 8px;
    padding: 12px 12px 0;
  }

  .main-partners__card-title {
    font-size: 12px;
    line-height: 1.33;
  }

  .main-partners__card-icon {
    max-width: 124px;
  }

  .main-partners__btn_type_mobile {
    font-size: 14px;
    line-height: 1.29;
    border-radius: 8px;
  }

  .main-partners__btn:hover {
    background: rgba(var(--neutral-bg-with-opacity), 0.1);
  }
}

@media screen and (max-width: 600px) {
  .main-partners__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .main-partners__card-icon {
    transform: scale(0.9);
  }
}
