.checkbox {
  width: 100%;
}

.checkbox__container {
  width: 100%;
  display: grid;
  grid-template-columns: 24px 1fr;
  align-items: center;
  gap: 12px;
}

.checkbox__invisible-input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.checkbox__pseudo-item {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid var(--secondary-active);
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;
}

.checkbox__pseudo-item:hover {
  box-shadow: 0px 0px 6px 0px var(--secondary-active);
}

.checkbox__check-icon {
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.checkbox__pseudo-item_checked .checkbox__check-icon {
  opacity: 1;
}

.checkbox__check-icon-fill {
  stroke: var(--neutral-active);
}

.checkbox__label {
  font-size: 12px;
  font-weight: 300;
  line-height: 1.16;
  opacity: 0.7;
}

.checkbox__label a {
  color: var(--secondary-active);
  text-decoration-line: underline;
}

@media screen and (max-width: 900px) {
  .checkbox__pseudo-item:hover {
    box-shadow: none;
  }
}
