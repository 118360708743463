.input {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: relative;
  border-radius: 8px;
  border: 1px solid var(--secondary-active);
  box-sizing: border-box;
  padding: 7px 11px;
  transition: box-shadow 0.2s ease-in-out;
}

.input_type_select {
  border: none;
  padding: 0;
}

.input_focused {
  box-shadow: 0px 0px 6px 0px var(--secondary-active);
}

.input__label {
  font-size: 10px;
  font-weight: 300;
  line-height: 1.2;
  opacity: 0.7;
}

.input__input {
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.14;
  resize: none;
}

.input__select-active {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--secondary-active);
  box-sizing: border-box;
  padding: 14px 11px;
  transition: all 0.2s ease-in-out;
}

.input__select-active_opened {
  border: 1px solid var(--select-border);
}

.input__select-active_disabled {
  pointer-events: none;
  user-select: none;
}

.input__select-active:hover {
  box-shadow: 0px 0px 6px 0px var(--secondary-active);
}

.input__select-value {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.14;
  transition: all 0.2s ease-in-out;
}

.input__arrow-container {
  width: 12px;
  height: 22px;
}

.input__select-arrow {
  width: 100%;
  height: 100%;
}

.input__select-arrow-stroke {
  stroke: var(--primary-active);
}

.input__dropdown {
  width: 100%;
  max-height: 300px;
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  border-radius: 8px;
  overflow: scroll;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  z-index: 100;
}

.input__dropdown_opened {
  visibility: visible;
  opacity: 1;
}

.input__dropdown-container {
  width: 100%;
  overflow: hidden;
  background: var(--select-dropdown);
  box-sizing: border-box;
  padding: 4px 12px;
}

.input__dropdown-list {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.input__dropdown-item {
  width: 100%;
  border-bottom: 1px solid rgba(var(--neutral-bg-with-opacity), 0.1);
}

.input__dropdown-item:last-child {
  border-bottom: none;
}

.input__dropdown-btn {
  width: calc(100% + 16px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.14;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 12px 8px;
  margin-left: -8px;
  transition: all 0.2s ease-in-out;
}

.input__dropdown-btn:hover {
  background: rgba(var(--neutral-bg-with-opacity), 0.1);
}

.input__dropdown-item_selected .input__dropdown-btn {
  pointer-events: none;
  user-select: none;
}

.input__dropdown-item_selected .input__select-value {
  opacity: 0.6;
}

.input__dropdown-check {
  width: 10px;
  height: 12px;
}

.input__dropdown-check-icon {
  width: 100%;
  height: 100%;
}

.input__dropdown-check-icon-stroke {
  stroke: var(--neutral-active);
}

@media screen and (max-width: 900px) {
  .input__select-active:hover {
    box-shadow: none;
  }

  .input__dropdown-btn:hover {
    background: transparent;
  }
}
