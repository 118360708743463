.main-join {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background: var(--primary-background);
  box-sizing: border-box;
  padding: 0 32px;
}

.main-join__content {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  gap: 60px;
  position: relative;
  background-image: url(../../../assets/images/main/join/bg-img.webp);
  background-size: 446px 488px;
  background-position: top 30px center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding: 120px 0;
}

.main-join__text-block {
  max-width: 340px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.main-join__title {
  color: var(--primary-active);
  font-size: 48px;
  font-weight: 700;
  line-height: 1.04;
  letter-spacing: 1.92px;
  text-transform: uppercase;
}

.main-join__text {
  font-size: 28px;
  font-weight: 500;
  line-height: 1.21;
}

.main-join__info-block {
  width: 100%;
  max-width: 724px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
}

.main-join__numbers-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.main-join__numbers-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.main-join__numbers-number {
  font-size: 48px;
  font-weight: 500;
  line-height: 1.04;
  letter-spacing: 1.92px;
  text-transform: uppercase;
}

.main-join__numbers-text {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.25;
}

.main-join__buttons-block {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.main-join__circle {
  width: 602px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background: var(--main-join-circle-color);
  position: absolute;
  top: -508px;
  right: -231px;
}

@media screen and (max-width: 1200px) {
  .main-join__circle {
    right: -331px;
  }
}

@media screen and (max-width: 1050px) {
  .main-join__content {
    gap: 40px;
  }

  .main-join__text-block {
    max-width: 300px;
  }

  .main-join__title {
    font-size: 44px;
  }

  .main-join__text {
    font-size: 24px;
  }

  .main-join__numbers-number {
    font-size: 44px;
  }

  .main-join__numbers-text {
    font-size: 20px;
  }

  .main-join__circle {
    right: -450px;
  }
}

@media screen and (max-width: 900px) {
  .main-join {
    padding: 0;
  }

  .main-join__content {
    flex-direction: column;
    gap: 20px;
    background-size: 357px 390px;
    padding: 40px 26px;
  }

  .main-join__text-block {
    max-width: 100%;
    gap: 12px;
  }

  .main-join__title {
    font-size: 36px;
    line-height: 1.11;
    letter-spacing: 1.44px;
  }

  .main-join__text {
    font-size: 20px;
    line-height: 1.3;
  }

  .main-join__info-block {
    max-width: 100%;
    gap: 30px;
  }

  .main-join__numbers-number {
    font-size: 32px;
    line-height: 1.19;
    letter-spacing: 1.28px;
  }

  .main-join__numbers-text {
    font-size: 18px;
    line-height: 1.11;
  }

  .main-join__buttons-block {
    gap: 12px;
  }

  .main-join__circle {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .main-join__content {
    background-size: 285px 312px;
    background-position: top 48px right -59px;
  }

  .main-join__numbers-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: start;
    gap: 16px;
  }

  .main-join__buttons-block {
    grid-template-columns: 2fr 1fr;
  }
}
