.speakers {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 32px;
}

.speakers__content {
  width: 100%;
  max-width: 1200px;
  position: relative;
  box-sizing: border-box;
  padding: 120px 0;
}

.speakers__list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: minmax(min-content, max-content);
  grid-auto-rows: minmax(min-content, max-content);
  gap: 60px 32px;
}

.speakers__title-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-column: 1/3;
}

.speakers__subtitle {
  color: var(--primary-active);
  font-size: 48px;
  font-weight: 700;
  line-height: 1.04;
  letter-spacing: 1.92px;
  text-transform: uppercase;
}

.speakers__title {
  font-size: 130px;
  font-weight: 500;
  line-height: 1.01;
  padding-top: 10px;
}

.speakers__btn-block {
  width: 100%;
  display: flex;
  gap: 15px;
  margin-top: 40px;
}

.speakers__arrow-icon {
  width: 13px;
  height: 13px;
  margin-top: 5px;
}

.speakers__arrow-icon-fill {
  fill: var(--neutral-active);
}

.speakers__download-btn {
  width: 52px;
  height: 52px;
  display: flex;
  transition: opacity 0.15s ease-in-out;
}

.speakers__download-btn:hover {
  opacity: 0.8;
}

.speakers__download-icon {
  width: 100%;
  height: 100%;
}

.speakers__download-icon-stroke {
  stroke: var(--neutral-active);
}

.speakers__card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 20px;
  background: rgba(var(--neutral-bg-with-opacity), 0.8);
  backdrop-filter: blur(7px);
  box-sizing: border-box;
  padding: 24px;
}

.speakers__card-heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.speakers__card-title {
  max-width: 120px;
  color: var(--neutral-secondary-active);
  font-size: 26px;
  font-weight: 400;
  line-height: 1.15;
}

.speakers__card-icon {
  width: 30px;
  height: 30px;
}

.speakers__card-icon-stroke {
  stroke: var(--neutral-secondary-active);
}

.speakers__card-text {
  flex: auto;
  color: var(--neutral-secondary-active);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
}

.speakers__bg {
  width: 4096px;
  height: 2731px;
  object-fit: fill;
  mix-blend-mode: color-dodge;
  pointer-events: none;
  user-select: none;
  position: absolute;
  top: -559px;
  left: -1403px;
  z-index: -1;
}

@media screen and (max-width: 1180px) {
  .speakers__list {
    grid-template-columns: repeat(3, 1fr);
  }

  .speakers__title-block {
    grid-column: 1/-1;
  }
}

@media screen and (max-width: 900px) {
  .speakers {
    padding: 0 26px;
  }

  .speakers__content {
    padding: 40px 0;
  }

  .speakers__list {
    gap: 24px;
  }

  .speakers__title-block {
    margin-bottom: 6px;
  }

  .speakers__subtitle {
    font-size: 22px;
    line-height: 1.09;
    letter-spacing: 0.88px;
  }

  .speakers__title {
    font-size: 52px;
    line-height: 1.07;
    padding-top: 4px;
  }

  .speakers__btn-block {
    gap: 12px;
    margin-top: 30px;
  }

  .speakers__arrow-icon {
    width: 10px;
    height: 10px;
    margin-top: 3px;
  }

  .speakers__download-btn {
    width: 44px;
    height: 44px;
  }

  .speakers__download-btn:hover {
    opacity: 1;
  }

  .speakers__card {
    gap: 10px;
    border-radius: 12px;
    padding: 20px;
  }

  .speakers__card-title {
    max-width: 220px;
  }

  .speakers__bg {
    width: 2885px;
    height: 1924px;
    transform: rotate(90deg);
    top: 191px;
    left: -889px;
  }
}

@media screen and (max-width: 750px) {
  .speakers__list {
    grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
  }

  .speakers__link-btn {
    max-width: 100% !important;
  }

  .speakers__card {
    grid-column: 1/-1;
    margin-top: 6px;
  }
}
