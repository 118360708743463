.person-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.person-card__photo {
  width: 100%;
  max-width: 160px;
  height: fit-content;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.person-card__info {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.person-card__title {
  font-size: 22px;
  font-weight: 400;
  line-height: 1.18;
}

.person-card__subtitle {
  color: var(--primary-active);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  padding-top: 6px;
}

.person-card__text {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.28;
  opacity: 0.7;
  padding-top: 12px;
}

@media screen and (max-width: 900px) {
  .person-card__title {
    font-size: 20px;
    line-height: 1.2;
  }

  .person-card__subtitle {
    font-size: 14px;
    line-height: 1.29;
  }

  .person-card__text {
    padding-top: 10px;
  }
}

@media screen and (max-width: 750px) {
  .person-card {
    flex-direction: row;
    gap: 16px;
  }

  .person-card__photo {
    max-width: 70px;
  }

  .person-card__info {
    margin-top: 11px;
  }
}
