.main-heading {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 32px;
}

.main-heading__content {
  width: 100%;
  max-width: 1200px;
  position: relative;
  box-sizing: border-box;
  padding: 194px 0 110px;
}

.main-heading__text-block {
  width: 100%;
  max-width: 640px;
  display: grid;
  grid-template-columns: repeat(2, minmax(min-content, max-content));
  grid-template-rows: repeat(2, minmax(min-content, max-content));
  grid-template-areas:
    'date text'
    '. buttons';
  gap: 40px;
  position: relative;
  z-index: 1;
}

.main-heading__date {
  grid-area: date;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 6px solid var(--primary-active);
  padding: 0 8px;
}

.main-heading__date-text {
  color: var(--primary-active);
  text-align: center;
  font-size: 24px;
  font-weight: 800;
  line-height: 1.16;
  letter-spacing: 0.96px;
  text-transform: uppercase;
  padding: 2px 0 8px;
}

.main-heading__date-text::after {
  content: '';
  display: block;
  width: 100%;
  height: 3px;
  background-color: var(--primary-active);
  margin-top: 8px;
}

.main-heading__text {
  grid-area: text;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.main-heading__title {
  font-size: 48px;
  font-weight: 800;
  line-height: 1.04;
  letter-spacing: 1.92px;
  text-transform: uppercase;
}

.main-heading__title_type_color {
  color: var(--primary-active);
}

.mmain-heading__title_weight_semibold {
  font-weight: 600;
}

.main-heading__subtitle {
  max-width: 380px;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.16;
}

.main-heading__buttons {
  grid-area: buttons;
  display: flex;
  gap: 12px;
}

.main-heading__image-block {
  width: 850px;
  aspect-ratio: 85/81;
  display: flex;
  position: absolute;
  right: -251px;
  top: -60px;
  z-index: 0;
}

.main-heading__image {
  width: 100%;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
  position: relative;
  z-index: 2;
}

.main-heading__small-ball {
  width: 119px;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
  position: absolute;
  top: 357px;
  left: 94px;
  z-index: 3;
}

.main-heading__big-ball {
  width: 220px;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
  position: absolute;
  top: 450px;
  left: 371px;
  z-index: 3;
}

.main-heading__rectangle {
  width: 394px;
  height: 566px;
  transform: rotate(45deg);
  opacity: 0.1;
  background: var(--main-heading-rectangle-color);
  position: absolute;
  top: 397px;
  left: -23px;
  z-index: 0;
}

.main-heading__circle {
  width: 602px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background: var(--main-heading-circle-color);
  filter: blur(232px);
  position: absolute;
  top: 152px;
  left: 0;
  z-index: 1;
}

@media screen and (max-width: 1200px) {
  .main-heading__image-block {
    right: -351px;
  }
}

@media screen and (max-width: 1050px) {
  .main-heading__image-block {
    right: -470px;
  }
}

@media screen and (max-width: 900px) {
  .main-heading {
    padding: 0 26px;
  }

  .main-heading__content {
    padding: 156px 0 40px;
  }

  .main-heading__text-block {
    max-width: 440px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .main-heading__date {
    width: fit-content;
    border: 4px solid var(--primary-active);
    padding: 12px 8px;
  }

  .main-heading__date-text {
    font-size: 18px;
    line-height: 1.2;
    letter-spacing: 0.72px;
    padding: 2px 0 4px;
  }

  .main-heading__date-text::after {
    height: 2px;
    margin-top: 4px;
  }

  .main-heading__text {
    margin-top: 6px;
  }

  .main-heading__title {
    font-size: 36px;
    line-height: 1.11;
    letter-spacing: 1.44px;
  }

  .main-heading__subtitle {
    max-width: 300px;
    font-size: 22px;
  }

  .main-heading__image-block {
    width: 100.59%;
    right: -31.95%;
    top: 0;
  }

  .main-heading__small-ball {
    width: 12.35%;
    top: 46.3%;
    left: 13.24%;
  }

  .main-heading__big-ball {
    width: 33.82%;
    top: 70.68%;
    left: 53.24%;
  }

  .main-heading__rectangle {
    width: 137.94%;
    height: 174.69%;
    top: 54.94%;
    left: -40%;
  }

  .main-heading__circle {
    top: 192px;
    left: 148px;
  }
}
