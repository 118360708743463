.opportunities {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background: var(--primary-background);
  box-sizing: border-box;
  padding: 0 32px;
}

.opportunities__content {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 60px;
  position: relative;
  box-sizing: border-box;
  padding: 120px 0 140px;
}

.opportunities__heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.opportunities__title {
  max-width: 370px;
  color: var(--primary-active);
  font-size: 62px;
  font-weight: 500;
  line-height: 1.06;
}

.opportunities__btn_type_mobile {
  display: none;
}

.opportunities__cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 1fr) minmax(min-content, max-content);
  gap: 12px;
}

.opportunities__card {
  width: 100%;
  height: 100%;
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 12px;
  background: rgba(var(--neutral-bg-with-opacity), 0.05);
  box-sizing: border-box;
  padding: 40px;
}

.opportunities__card:nth-child(7),
.opportunities__card:last-child {
  grid-column: span 3;
}

.opportunities__card-title {
  font-size: 26px;
  font-weight: 500;
  line-height: 1.23;
}

.opportunities__card-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  opacity: 0.7;
}

.opportunities__swiper-container {
  display: none;
}

@media screen and (max-width: 1050px) {
  .opportunities__cards {
    gap: 10px;
  }

  .opportunities__card {
    gap: 10px;
    padding: 30px 20px;
  }

  .opportunities__card-title {
    font-size: 23px;
  }

  .opportunities__card-text {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.29;
  }
}

@media screen and (max-width: 900px) {
  .opportunities {
    padding: 0 26px;
  }

  .opportunities__content {
    gap: 30px;
    padding: 40px 0 112px;
  }

  .opportunities__title {
    max-width: 300px;
    font-size: 36px;
    line-height: 1.11;
    letter-spacing: 1.44px;
  }

  .opportunities__card-title {
    font-size: 20px;
    line-height: 1.2;
  }
}

@media screen and (max-width: 750px) {
  .opportunities__btn {
    display: none;
  }

  .opportunities__btn_type_mobile {
    max-width: 100%;
    display: flex;
  }

  .opportunities__cards {
    display: none;
  }

  .opportunities__swiper-container {
    width: calc(100% + 2 * 26px);
    display: flex;
    margin-left: -26px;
  }

  .opportunities__swiper {
    box-sizing: border-box;
    padding: 0 26px 24px !important;
  }

  :root {
    --swiper-pagination-bottom: 0px;
    --swiper-pagination-bullet-size: 8px;
    --swiper-pagination-color: var(--primary-active);
    --swiper-pagination-bullet-inactive-color: var(--neutral-active);
    --swiper-pagination-bullet-inactive-opacity: 0.28;
    --swiper-pagination-bullet-horizontal-gap: 5px;
  }
}
