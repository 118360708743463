@font-face {
    font-family: 'Outfit';
    src: url('Outfit-Light.eot');
    src: url('Outfit-Light.eot?#iefix') format('embedded-opentype'),
        url('Outfit-Light.woff2') format('woff2'),
        url('Outfit-Light.woff') format('woff'),
        url('Outfit-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Outfit';
    src: url('Outfit-Medium.eot');
    src: url('Outfit-Medium.eot?#iefix') format('embedded-opentype'),
        url('Outfit-Medium.woff2') format('woff2'),
        url('Outfit-Medium.woff') format('woff'),
        url('Outfit-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Outfit';
    src: url('Outfit-ExtraLight.eot');
    src: url('Outfit-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('Outfit-ExtraLight.woff2') format('woff2'),
        url('Outfit-ExtraLight.woff') format('woff'),
        url('Outfit-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Outfit';
    src: url('Outfit-Regular.eot');
    src: url('Outfit-Regular.eot?#iefix') format('embedded-opentype'),
        url('Outfit-Regular.woff2') format('woff2'),
        url('Outfit-Regular.woff') format('woff'),
        url('Outfit-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
