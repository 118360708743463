.app {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-x: hidden;
}

.app__container {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}