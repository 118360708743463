.navbar {
  width: calc(100% + 2 * 32px);
  overflow-x: scroll;
  margin-left: -32px;
}

.navbar::-webkit-scrollbar {
  display: none;
}

.navbar__list {
  display: flex;
  gap: 8px;
}

.navbar__item:first-child {
  padding-left: 32px;
}

.navbar__item:last-child {
  padding-right: 32px;
}

.navbar__btn {
  min-width: 143px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 550;
  line-height: 1.14;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 12px 16px;
  transition: background 0.15s ease-in-out;
}

.navbar__btn:hover {
  background: rgba(var(--neutral-bg-with-opacity), 0.1);
}

.navbar__btn_active {
  background: var(--neutral-background);
  user-select: none;
  pointer-events: none;
}

@media screen and (max-width: 900px) {
  .navbar {
    width: calc(100% + 2 * 26px);
    margin-left: -26px;
  }

  .navbar__item:first-child {
    padding-left: 26px;
  }

  .navbar__item:last-child {
    padding-right: 26px;
  }

  .navbar__btn {
    min-width: 110px;
    font-size: 12px;
    line-height: 1.17;
    border-radius: 8px;
    padding: 8px 10px;
  }

  .navbar__btn:hover {
    background: transparent;
  }
}

@media screen and (max-width: 590px) {
  .navbar__btn {
    width: fit-content;
    min-width: unset;
  }
}
