.show {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background: var(--neutral-background);
  box-sizing: border-box;
  padding: 0 32px;
}

.show__content {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  padding: 80px 0 60px;
}

.show__banner {
  width: 886px;
  object-fit: contain;
  user-select: none;
  pointer-events: none;
  position: absolute;
  top: -250px;
  left: -20px;
  z-index: 2;
}

.show__glitter {
  aspect-ratio: 1/1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  z-index: 1;
}

.show__glitter_size_s {
  width: 352px;
  opacity: 0.3;
  bottom: -129px;
  left: -313px;
}

.show__glitter_size_m {
  width: 472px;
  top: -14px;
  left: 521px;
}

.show__glitter_size_l {
  width: 630px;
  top: -98px;
  left: 0;
}

.show__circle {
  width: 602px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background: var(--main-show-circle-color);
  filter: blur(232px);
  position: absolute;
  top: -284px;
  left: 225px;
  z-index: 0;
}

.show__gradient {
  width: 100vw;
  height: 157px;
  background: linear-gradient(180deg, rgba(1, 1, 2, 0) 0%, #000 100%);
  position: absolute;
  bottom: 0;
  z-index: 3;
}

.show__gradient_type_mobile {
  display: none;
}

.show__text-container {
  width: 100%;
  max-width: 1176px;
  display: flex;
  justify-content: flex-end;
  margin-right: auto;
  position: relative;
  z-index: 4;
}

.show__text-block {
  max-width: 312px;
  display: flex;
  flex-direction: column;
}

.show__title {
  font-size: 34px;
  font-weight: 700;
  line-height: 1.23;
  text-transform: uppercase;
}

.show__text {
  max-width: 293px;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.36;
  text-transform: uppercase;
  opacity: 0.7;
  padding-top: 25px;
}

.show__datetime {
  width: calc(100% + 28px + 47px);
  display: flex;
  align-items: center;
  gap: 18px;
  margin-top: 37px;
  margin-left: -75px;
}

.show__bolt-icon {
  width: 47px;
  height: 64px;
  margin-right: 10px;
}

.show__bolt-icon-fill {
  fill: var(--primary-active);
}

.show__date {
  font-size: 60px;
  font-weight: 700;
  line-height: 1.06;
}

.show__time-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid var(--neutral-active);
  box-sizing: border-box;
  padding: 8px 12px;
}

.show__time {
  font-size: 36px;
  font-weight: 600;
  line-height: 1.11;
}

@media screen and (max-width: 1200px) {
  .show__content {
    padding: 50px 0;
  }

  .show__banner {
    width: 709px;
    top: -200px;
    left: -16px;
  }

  .show__glitter_size_s {
    width: 282px;
    bottom: -103px;
    left: -250px;
  }

  .show__glitter_size_m {
    width: 377px;
    top: -11px;
    left: 417px;
  }

  .show__glitter_size_l {
    width: 504px;
    top: -78px;
  }

  .show__circle {
    left: 120px;
  }

  .show__text-container {
    max-width: 1050px;
  }

  .show__text-block {
    max-width: 280px;
  }

  .show__title {
    font-size: 30px;
  }

  .show__text {
    max-width: 250px;
    font-size: 18px;
  }

  .show__bolt-icon {
    width: 45px;
    height: 60px;
  }

  .show__date {
    font-size: 56px;
  }

  .show__time {
    font-size: 32px;
  }
}

@media screen and (max-width: 1024px) {
  .show__content {
    padding: 40px 0;
  }

  .show__banner {
    width: 567px;
    top: -160px;
    left: -13px;
  }

  .show__glitter_size_s {
    width: 226px;
    bottom: -86px;
    left: -208px;
  }

  .show__glitter_size_m {
    width: 302px;
    top: -10px;
    left: 334px;
  }

  .show__glitter_size_l {
    width: 403px;
    top: -62px;
  }

  .show__circle {
    left: 40px;
  }

  .show__text-container {
    max-width: 900px;
  }

  .show__text-block {
    max-width: 260px;
  }

  .show__title {
    max-width: 222px;
    font-size: 24px;
  }

  .show__text {
    font-size: 16px;
    padding-top: 10px;
  }

  .show__datetime {
    width: calc(100% + 26px + 41px);
    margin-top: 30px;
    margin-left: -65px;
  }

  .show__bolt-icon {
    width: 41px;
    height: 54px;
    margin-right: 8px;
  }

  .show__date {
    font-size: 50px;
  }

  .show__time {
    font-size: 28px;
  }

  .show__time-box {
    border: 3px solid var(--neutral-active);
    padding: 7px 23px;
  }

  .show__time {
    font-size: 30px;
    line-height: 1.13;
  }
}

@media screen and (max-width: 840px) {
  .show {
    padding: 0 26px;
  }

  .show__content {
    padding: 237px 0 40px;
  }

  .show__gradient {
    bottom: -51px;
  }

  .show__gradient_type_mobile {
    display: block;
    height: 286px;
    bottom: 50px;
    background: linear-gradient(180deg, rgba(1, 1, 2, 0) 0%, #000 90.78%);
  }

  .show__text-container {
    justify-content: flex-start;
  }

  .show__text-block {
    max-width: 338px;
  }

  .show__text {
    max-width: 338px;
  }

  .show__datetime {
    width: 100%;
    margin-left: 0;
  }
}

@media screen and (max-width: 520px) {
  .show__content {
    padding: 187px 0 40px;
  }

  .show__banner {
    width: 404px;
    top: -113px;
    left: -10px;
  }

  .show__glitter_size_s {
    width: 307px;
    top: 225px;
    left: -95px;
  }

  .show__glitter_size_m {
    width: 296px;
    top: -54px;
    left: 70px;
  }

  .show__glitter_size_l {
    display: none;
  }

  .show__circle {
    top: -403px;
    left: calc(50% - 301px);
  }

  .show__gradient_type_mobile {
    height: 206px;
    bottom: 135px;
  }
}
