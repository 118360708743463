.form-popup__form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.form-popup__input {
  width: 100%;
}

.form-popup__input_type_textarea {
  height: 80px;
}

.form-popup__submit-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 550;
  line-height: 1.85;
  border-radius: 8px;
  background: var(--secondary-active);
  box-sizing: border-box;
  padding: 10px 0px;
  margin-top: 12px;
  transition: all 0.2s ease-in-out;
}

.form-popup__submit-button:hover {
  opacity: 0.8;
}

.form-popup__submit-button_disabled {
  background: var(--primary-selection);
  user-select: none;
  pointer-events: none;
}

@media screen and (max-width: 900px) {
  .form-popup__submit-button:hover {
    opacity: 1;
  }
}
